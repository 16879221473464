import React from 'react';

import { StyledLink } from './layout/typography';

import formatGCMSDate from '../utils/date';

export default function Publication({ publication }) {
  let formattedDate = formatGCMSDate(publication.date);

  return (
    <div key={publication.title}>
      <div className="h-full flex flex-col justify-between">
        <div>
          <div className="flex gap-x-1">
            {publication.tags.map((tag) => (
              <div key={tag.name} className="inline-block">
                <span
                  className="uppercase inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium"
                  style={{
                    color: tag.textColor.hex,
                    backgroundColor: tag.backgroundColor.hex,
                  }}
                >
                  {tag.name}
                </span>
              </div>
            ))}
          </div>
          <a
            href={publication.url}
            target="_blank"
            rel="noreferrer"
            className="block mt-4"
          >
            <p className="text-base text-gray-500 leading-8">{formattedDate}</p>
            <p className="text-xl font-semibold text-gray-900">
              {publication.title}
            </p>
            <p className="mt-3 text-base text-gray-500">
              {publication.excerpt}
            </p>
          </a>
        </div>
        <div className="mt-3">
          <a
            href={publication.url}
            target="_blank"
            rel="noreferrer"
            className="text-base font-semibold text-timothy-blue hover:text-blue-500"
          >
            <StyledLink>Read more &rarr;</StyledLink>
          </a>
        </div>
      </div>
    </div>
  );
}
