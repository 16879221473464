import React from 'react';
import { Link } from 'gatsby';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

export default function Pagination({
  prefix,
  currentPage,
  hasNextPage,
  hasPreviousPage,
  pageCount,
  perPage,
  totalCount,
}) {
  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t-2 border-gray-200 sm:px-6 max-w-7xl mx-auto pt-12">
      <div className="flex-1 flex justify-between sm:hidden">
        {hasPreviousPage ? (
          <Link
            to={`/${prefix}/${currentPage - 1}`}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </Link>
        ) : (
          <div className="cursor-not-allowed relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-200">
            Previous
          </div>
        )}
        {hasNextPage ? (
          <Link
            to={`/${prefix}/${currentPage + 1}`}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </Link>
        ) : (
          <div className="cursor-not-allowed relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-200">
            Next
          </div>
        )}
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing{' '}
            <span className="font-medium">
              {Math.max((currentPage - 1) * perPage, 1)}
            </span>{' '}
            to{' '}
            <span className="font-medium">
              {Math.min(currentPage * perPage, totalCount)}
            </span>{' '}
            of <span className="font-medium">{totalCount}</span> results
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            {hasPreviousPage ? (
              <Link
                to={`/${prefix}/${currentPage - 1}`}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </Link>
            ) : (
              <div className="cursor-not-allowed relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-200">
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </div>
            )}
            {[...Array(pageCount)].map((_, index) =>
              index + 1 === currentPage ? (
                <div
                  aria-current="page"
                  className="cursor-not-allowed z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                >
                  {index + 1}
                </div>
              ) : (
                <Link
                  to={
                    index + 1 === 1 ? `/${prefix}` : `/${prefix}/${index + 1}`
                  }
                  className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                >
                  {index + 1}
                </Link>
              )
            )}
            {hasNextPage ? (
              <Link
                to={`/${prefix}/${currentPage + 1}`}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </Link>
            ) : (
              <div className="cursor-not-allowed relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-200">
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </div>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
}
